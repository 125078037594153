<template>
  <base-section
    id="banner-solar"
    class="my-0"
  >
    <v-parallax
    :height="$vuetify.breakpoint.smAndDown ? 300 : 400"
    src="@/assets/RH Solar Robots_cover.jpg"
    style="background-image: linear-gradient(200deg,#0e469c, #004d80);"
    >
      <v-container>
        <v-row
          class="mx-auto"
        >
          <v-col
            v-for="value in stats"
            :key="value"
            cols="12"
            md="12"
          >
            <div class="text-center">
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'text-h3' : 'text-h2'"
                class="font-weight-black mb-4 white--text"
                v-text="value"
              ></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionBannerSolar',

    data: () => {
      return {
        stats: [
          '',
        ],
      }
    },
  }
</script>
